<template>
  <div class="header">
    <a href="#">
      <i class="fas fa-arrow-left"></i>
      Back
    </a>
    <h1>Request details - Closed</h1>
  </div>

 <div class="container">
    <div class="request-details">
      <div>
        <h2>Need help to make user interface</h2>
        <p>Oct 21, 2023 12:06 AM</p>
        <div class="tags">
          <div class="tag">Node.js</div>
          <div class="tag">Unit testing</div>
          <div class="tag">Firebase</div>
        </div>
        <p style="margin:10px 0px 0px 10px">
          View more
          <i class="fas fa-chevron-down"></i>
        </p>
      </div>
      <div>
        <div class="icons">
          <i class="fas fa-edit"></i>
          <i class="fas fa-paperclip"></i>
          <i class="fas fa-trash"></i>
        </div>
        <div class="budget">Budget: AED 600.00</div>
      </div>
    </div>
  </div>
  <h6 class="mentor_title">Mentors</h6>
  <div class="container">
    <div class="mentors">
      <h3>Interested Mentors (26)</h3>
      <p>
        These mentors have indicated interest in helping you. See below to
        message and hire them for a session or job.
      </p>
      <div class="mentor-card" @click="openPopup(mentor)" v-for="mentor in mentors" :key="mentor.id">
        <img :alt="'Profile picture of ' + mentor.name" :src="mentor.image" />
        <div class="mentor-info">
          <h4>{{ mentor.name }}</h4>
          <div class="rating">
            <span>{{ mentor.rating }}</span>
            <i class="fas fa-star" v-for="star in 5" :class="{'active': star <= mentor.rating}" :key="star"></i>
          </div>
          <div class="price">{{ mentor.price }}</div>
          <div class="description">{{ mentor.description }}</div>
          <p class="profile-message">{{ mentor.message }}</p>
        </div>
        <div class="mentor-actions">
          <button class="message" @click.stop>Message</button>
          <button class="hire" @click.stop="hireModalShow(mentor)">Hire</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Popup -->
  <div v-if="showPopup" class="popup">
    <div class="profile-container">
      <button class="close-button" @click="closePopup">×</button>
      <div class="left-side">
        <img :alt="'Profile picture of ' + selectedMentor.name" :src="selectedMentor.image" height="80" width="80" />
        <button class="message-button">Message {{ selectedMentor.name }}</button>
        <div class="help-box">
          <p>{{ selectedMentor.message }}</p>
        </div>
      </div>
      <div class="right-side">
        <div class="profile-header">
          <div>
            <h2>{{ selectedMentor.name }}</h2>
            <p>{{ selectedMentor.description }}</p>
            <div class="info">
              <i class="fas fa-calendar-alt"></i>
              Joined September 2023
            </div>
            <div class="info">
              <i class="fas fa-briefcase"></i>
              696 Sessions / Jobs
            </div>
          </div>
        </div>
        <p>{{ selectedMentor.description }}</p>
        <p>I love sharing my knowledge with developers to help them better at coding.</p>
        <a href="#">Read more</a>
        <div class="technical-expertise">
          <h3>Technical Expertise</h3>
          <div class="tags">
            <div class="tag" v-for="tag in selectedMentor.tags" :key="tag">{{ tag }}</div>
          </div>
        </div>
        <div class="reviews">
          <h3>Reviews From Clients</h3>
          <div class="review-item" v-for="review in selectedMentor.reviews" :key="review.id">
            <img :alt="'Profile picture of reviewer'" :src="review.reviewerImage" height="40" width="40" />
            <div class="review-content">
              <p>
                <strong>{{ review.reviewerName }}</strong>
                <span class="rating">{{ review.rating }} <i class="fas fa-star"></i></span>
              </p>
              <p>{{ review.comment }}</p>
              <p>{{ review.date }}</p>
            </div>
          </div>
        </div>
        <div class="view-more">View More</div>
      </div>
    </div>
  </div>


  <div class="modal" id="hireModal">
    <div class="modal-content">
      <div class="close-icon" @click="closeHireModal">&times;</div>
      <div class="modal-header">
        Hire {{ selectedMentor.name }}
      </div>
      <div class="modal-subheader">
        Mentorship sessions
      </div>      
      <div class="horizontal-line"></div>
      <div class="session-options">
        <div class="session-option">
          <i class="fas fa-video"></i>
          <p>Start Session</p>
          <p class="status">Mentor isn't online</p>
        </div>
        <div class="session-option">
          <i class="fas fa-calendar-alt"></i>
          <p>Schedule Session</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopup: false,
      selectedMentor: {},
      mentors: [
        {
          id: 1,
          name: "Patrick Doe",
          rating: 5.0,
          price: "AED 15.00 / 15 Min",
          description: "Freelancer and a Full Stack Developer with 10+ years",
          message: "Hi, I can help you with this. Let me know when you're available for a session.",
          image: "https://storage.googleapis.com/a1aa/image/56XtXlN_uD-_6bHRp7TQFVwS7YJLVBFw3GbhIMokqPc.jpg",
          tags: ["Node.js", "Unit testing", "Firebase"],
          reviews: [
            {
              id: 1,
              reviewerName: "Client A",
              reviewerImage: "https://storage.googleapis.com/a1aa/image/hqbclrFtaUhNHZ_vosQ4RnNbqYEN9ZoJmadu-CqbsNM.jpg",
              rating: 5.0,
              comment: "Very good. Would recommend.",
              date: "Sep 24, 2023"
            }
          ]
        },
        {
          id: 2,
          name: "Jane Smith",
          rating: 4.8,
          price: "AED 20.00 / 15 Min",
          description: "Expert in UI/UX design with 5+ years of experience.",
          message: "I would love to assist you with your project!",
          image: "https://storage.googleapis.com/a1aa/image/56XtXlN_uD-_6bHRp7TQFVwS7YJLVBFw3GbhIMokqPc.jpg",
          tags: ["UI/UX", "Design", "Prototyping"],
          reviews: [
            {
              id: 2,
              reviewerName: "Client B",
              reviewerImage: "https://storage.googleapis.com/a1aa/image/hqbclrFtaUhNHZ_vosQ4RnNbqYEN9ZoJmadu-CqbsNM.jpg",
              rating: 4.8,
              comment: "Great designer, very helpful.",
              date: "Oct 1, 2023"
            }
           
          ]
        },
        {
          id: 3,
          name: "John Doe",
          rating: 4.5,
          price: "AED 25.00 / 15 Min",
          description: "Specialist in front-end development.",
          message: "Let's work together to create something amazing!",
          image: "https://storage.googleapis.com/a1aa/image/56XtXlN_uD-_6bHRp7TQFVwS7YJLVBFw3GbhIMokqPc.jpg",
          tags: ["JavaScript", "React", "CSS"],
          reviews: [
            {
              id: 3,
              reviewerName: "Client C",
              reviewerImage: "https://storage.googleapis.com/a1aa/image/hqbclrFtaUhNHZ_vosQ4RnNbqYEN9ZoJmadu-CqbsNM.jpg",
              rating: 4.5,
              comment: "Very knowledgeable and easy to work with.",
              date: "Oct 5, 2023"
            }
          ]
        }
      ]
    };
  },
 methods: {
  openPopup(mentor) {
    this.selectedMentor = mentor;
    this.showPopup = true;
  },
  closePopup() {
    this.showPopup = false;
    this.selectedMentor = {};
  },
  hireModalShow(mentor) {
    this.selectedMentor = mentor;
    let modal = document.getElementById("hireModal");
    if (modal) {
      modal.style.display = "flex";
    }
  },
  closeHireModal() {
    let modal = document.getElementById("hireModal");
    if (modal) {
      modal.style.display = "none";
    }
  }
}
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 0px;
  margin-left: 50px;
}
.header a {
  text-decoration: none;
  color: #000;
  font-size: 16px;
  margin-right: 10px;
}
.header h1 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
}
.container {
  width: 90%;
  max-width: 1200px;
  margin: 0px 0px 30px 50px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.mentor_title {
  font-size: 1rem;
  margin-left: 60px;
}
.request-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.request-details h2 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.request-details .tags {
  display: flex;
  gap: 10px;
}
.tag {
  background-color: #e0e0e0;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
.budget {
  font-size: 16px;
  font-weight: 500;
}
.view-more {
  color: #000;
  cursor: pointer;
  text-align: center;
}
.view-more i {
  margin-left: 5px;
}
.mentors {
  margin-top: 20px;
}
.mentors h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.mentors p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}
.mentor-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  cursor: pointer; /* Make the card clickable */
}
.mentor-card img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.mentor-info {
  flex-grow: 1;
}
.mentor-info h4 {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}
.mentor-info .rating {
  display: flex;
  align-items: center;
  margin: 5px 0;
}
.mentor-info .rating i {
  color: #ffc107;
  margin-right: 5px;
}
.mentor-info .price {
  font-size: 14px;
  color: #666;
}
.mentor-info .description {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}
.mentor-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.mentor-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}
.mentor-actions .message {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.mentor-actions .hire {
  background-color: #004d66;
  color: #fff;
}
.icons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.icons i {
  cursor: pointer;
  color: #000;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.profile-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 800px;
  padding: 20px;
  display: flex;
  position: relative;
}
.left-side {
  width: 40%;
  padding-right: 20px;
  border-right: 1px solid #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-side {
  width: 60%;
  padding-left: 20px;
}
.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.profile-header img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}
.profile-header h2 {
  margin: 0;
  font-size: 20px;
}
.profile-header p {
  margin: 5px 0;
  color: #666;
}
.profile-header .info {
  display: flex;
  align-items: center;
  color: #666;
}
.profile-header .info i {
  margin-right: 5px;
}
.message-button {
  background-color: #00bfa5;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.message-button:hover {
  background-color: #009688;
}
.help-box {
  background-color: #f5f5f5;
  border-left: 4px solid #00bfa5;
  padding: 10px;
  margin: 20px 0;
}
img, video {
    max-width: 100%;
    border-radius:50%;
    height: auto;
}
.help-box p {
  margin: 0;
}
.technical-expertise {
  margin: 20px 0;
}
.technical-expertise h3 {
  margin: 0 0 10px 0;
}
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags .tag {
  background-color: #e0e0e0;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  font-size: 14px;
}
.reviews {
  margin: 20px 0;
}
.reviews h3 {
  margin: 0 0 10px 0;
}
.review-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.review-item img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.review-item .review-content {
  flex: 1;
}
.review-item .review-content p {
  margin: 0;
}
.review-item .review-content .rating {
  color: #f5a623;
}
.view-more {
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}
.view-more:hover {
  background-color: #e0e0e0;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}
.profile-message
{
background-color: #f5f5f5;
padding: 10px;
border-radius: 4px;
margin-right:50px;
position: relative;
}
.profile-message::before {
content: '';
position: absolute;
top: -10px;
left: 20px;
border-width: 10px;
border-style: solid;
border-color: transparent transparent #f5f5f5 transparent;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  text-align: center;
  position: relative;
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 20px;
  cursor: pointer;
}

.modal-header {
  font-size: 15px;
  font-weight: bold;
  text-align: center !important;
  width: 100%;
  display: block; 
  border-bottom: 0px solid #e0e0e0 !important;
}

.modal-subheader {
font-size: 16px;
color: #666;
margin-bottom: 10px;
}
.horizontal-line {
border-bottom: 1px solid #e0e0e0;
margin-bottom: 20px;
}
.session-options {
display: flex;
justify-content: space-around;
margin-bottom: 20px;
}
.session-option {
background-color: #f9f9f9;
border: 1px solid #ddd;
border-radius: 8px;
padding: 20px;
width: 45%;
text-align: center;
margin: 0 10px;
}
.session-option i {
font-size: 24px;
color: #004d66;
margin-bottom: 10px;
}.session-option p {
margin: 5px 0;
}
.session-option .status {
font-size: 12px;
color: #999;
}
.close-button {
background-color: white;
border-radius: 4px;
padding: 5px 10px;
color: #004d66;
cursor: pointer;
}
.close-icon {
position: absolute;
top: 1px;
right: 10px;
font-size: 25px;
cursor: pointer;
}
@media (max-width: 768px) {
  .mentor-card {
    flex-direction: column;
    align-items: flex-start;
  }
  .mentor-actions {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
}
</style>