import axios from 'axios';
const api = axios.create({
    // Live Frontend url
    baseURL: 'https://backend.mentorwise.org/api',
    // baseURL: 'http://127.0.0.1:8000/api',
});
export default api;





