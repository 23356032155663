<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 content">
                <h2>Users</h2>
                <!-- Add User Button -->
                <div class="d-flex mb-2">
                    <button type="button" class="btn bg-custom-color ms-auto" data-bs-toggle="modal"
                        data-bs-target="#addUser">
                        Add User
                    </button>
                </div>

                <!-- Add User Modal -->
                <div class="modal fade" id="addUser" tabindex="-1" aria-labelledby="addUserLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="addUserLabel">Add User</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    @click="resetForm"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="storeUser">
                                    <div class="my-3">
                                        <label for="first_name" class="form-label">First Name</label>
                                        <input type="text" v-model="first_name" placeholder="Enter First Name"
                                            class="form-control" required />
                                    </div>
                                    <div class="my-3">
                                        <label for="last_name" class="form-label">Last Name</label>
                                        <input type="text" v-model="last_name" placeholder="Enter Last Name"
                                            class="form-control" />
                                    </div>
                                    <div class="my-3">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="email" v-model="userEmail" placeholder="Enter Email"
                                            class="form-control" required />
                                    </div>
                                    <div class="my-3">
                                        <label for="password" class="form-label">Password</label>
                                        <input type="password" v-model="userPassword" placeholder="Enter Password"
                                            class="form-control" required />
                                    </div>
                                    <div class="my-3">
                                        <label for="password_confirmation" class="form-label">Confirm Password</label>
                                        <input type="password" v-model="password_confirmation"
                                            placeholder="Confirm Password" class="form-control" required />
                                    </div>
                                    <div class="my-3">
                                        <label for="role" class="form-label">Role</label>
                                        <select v-model="userRole" class="form-control" required>
                                            <option value="" disabled>Select Role</option>
                                            <option v-for="role in roles" :key="role.role_id" :value="role.role_id">
                                                {{ role.role_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <button type="submit" class="btn bg-custom-color">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Update User Modal -->
                <div v-if="modalVisible" class="modal fade show" id="updateUser" tabindex="-1"
                    aria-labelledby="updateUserLabel" aria-hidden="true" style="display: block;">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="updateUserLabel">Update User</h5>
                                <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <form @submit.prevent="updateUser">
                                    <div class="my-3">
                                        <label for="first_name" class="form-label">First Name</label>
                                        <input type="text" v-model="first_name" placeholder="Enter First Name"
                                            class="form-control" />
                                    </div>
                                    <div class="my-3">
                                        <label for="last_name" class="form-label">Last Name</label>
                                        <input type="text" v-model="last_name" placeholder="Enter Last Name"
                                            class="form-control" />
                                    </div>
                                    <div class="my-3">
                                        <label for="email" class="form-label">Email</label>
                                        <input type="email" v-model="userEmail" placeholder="Enter Email"
                                            class="form-control" />
                                    </div>
                                    <div class="my-3">
                                        <label for="password" class="form-label">Password</label>
                                        <input type="password" v-model="userPassword" placeholder="Enter password"
                                            class="form-control" />
                                    </div>
                                    <div class="my-3">
                                        <label for="password_confirmation" class="form-label">Confirm Password</label>
                                        <input type="password" v-model="password_confirmation"
                                            placeholder="Confirm password" class="form-control" />
                                    </div>
                                    <div class="my-3">
                                        <label for="role" class="form-label">Role</label>
                                        <select v-model="userRole" class="form-control">
                                            <option v-for="role in roles" :key="role.role_id" :value="role.role_id">
                                                {{ role.role_name }}
                                            </option>
                                        </select>
                                    </div>
                                    <button type="submit" class="btn bg-custom-color">Update</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- User Table -->
                <div>
                    <table class="table table-striped table-bordered text-center">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Role</th>
                                <th>Created At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="paginatedUsers.length === 0">
                                <td colspan="7" class="text-center">No users found</td>
                            </tr>
                            <tr v-for="(user, index) in paginatedUsers" :key="user.user_id">
                                <td>{{ (currentPage - 1) * itemsPerPage + index + 1 }}</td>
                                <td>{{ user.first_name }}</td>
                                <td>{{ user.last_name }}</td>
                                <td>{{ user.email }}</td>
                                <td>{{ user.role_name }}</td>
                                <td>{{ formatDate(user.created_at) }}</td>
                                <td>
                                    <button class="btn btn-primary button-spacing"
                                        @click="editUser (user.user_id)">Edit</button>
                                    <button class="btn btn-danger" @click="deleteUser (user.user_id)">Delete</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- Pagination -->
                <div class="d-flex align-items-center mt-3">
                    <button class="btn btn bg-custom-color  me-2" :disabled="currentPage === 1" @click="currentPage--">Previous</button>
                    <span class="me-2">Page {{ currentPage }} of {{ totalPages }}</span>
                    <button class="btn btn bg-custom-color" :disabled="currentPage === totalPages" @click="currentPage++">Next</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import api from "../../api";

export default {
    data() {
        return {
            users: [],
            roles: [],
            first_name: "",
            last_name: "",
            userEmail: "",
            userPassword: "",
            password_confirmation: "",
            userRole: null,
            currentUserId: null,
            currentPage: 1,
            itemsPerPage: 7,
            modalVisible: false, 
        };
    },
    mounted() {
        this.fetchUsers();
        this.fetchRoles();
    },
    computed: {
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.users.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.users.length / this.itemsPerPage);
        }
    },
    methods: {
        formatDate(dateString) {
            const date = new Date(dateString);
            return date.toLocaleDateString();
        },
        async fetchUsers() {
            const token = localStorage.getItem("token");
            try {
                const response = await api.get("/users", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                this.users = response.data.users || [];
            } catch (error) {
                console.error("Error fetching users:", error);
                this.handleAuthError(error);
            }
        },
        async fetchRoles() {
            const token = localStorage.getItem("token");
            try {
                const response = await api.get("/users/create", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                this.roles = response.data.users || [];
            } catch (error) {
                console.error("Error fetching roles:", error);
                this.handleAuthError(error);
            }
        },
        async storeUser() {
            const token = localStorage.getItem("token");
            try {
                const response = await api.post("/users/register", {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.userEmail,
                    password: this.userPassword,
                    password_confirmation: this.password_confirmation,
                    role_id: this.userRole,
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                if (response.data.status) {
                    Swal.fire("Success", "User added successfully!", "success");
                    this.fetchUsers();
                    this.resetForm();
                }
            } catch (error) {
                Swal.fire("Error", "Error adding user.", "error");
            }
        },
        async editUser(userId) {
            const token = localStorage.getItem("token");
            try {
                const response = await api.get(`/users/edit/${userId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const user = response.data.user;
                this.first_name = user.first_name;
                this.last_name = user.last_name;
                this.userEmail = user.email;
                this.userPassword = "";
                this.password_confirmation = "";
                this.userRole = user.role_id;
                this.currentUserId = user.user_id;
                this.modalVisible = true;
            } catch (error) {
                Swal.fire("Error", "Error fetching user data.", "error");
            }
        },
        async updateUser() {
            const token = localStorage.getItem("token");
            try {
                const response = await api.put(`/users/update/${this.currentUserId}`, {
                    first_name: this.first_name,
                    last_name: this.last_name,
                    email: this.userEmail,
                    password: this.userPassword || undefined, 
                    password_confirmation: this.password_confirmation || undefined,
                    role_id: this.userRole,
                }, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data.status) {
                    Swal.fire("Success", "User updated successfully!", "success");
                    this.fetchUsers();
                    this.closeModal();
                }
            } catch (error) {
                Swal.fire("Error", "Error updating user.", "error");
            }
        },
        async deleteUser(user_id) {
            const token = localStorage.getItem("token");
            if (!token) {
                Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
                return;
            }
            const result = await Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
            });

            // Proceed with deletion if the user confirms
            if (result.isConfirmed) {
                try {
                    const response = await api.delete(`/users/delete/${user_id}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    if (response.data.status) {
                        Swal.fire("Deleted!", response.data.message, "success");
                        this.fetchUsers();
                    } else {
                        Swal.fire("Error", response.data.message, "error");
                    }
                } catch (error) {
                    console.error("Error deleting user:", error);
                    Swal.fire("Error", "An error occurred while deleting the user.", "error");
                }
            }
        }

        ,
        closeModal() {
            this.modalVisible = false;
            this.resetForm();
        },
        resetForm() {
            this.first_name = "";
            this.last_name = "";
            this.userEmail = "";
            this.userPassword = "";
            this.password_confirmation = "";
            this.userRole = null;
            this.currentUserId = null;
        },
        handleAuthError(error) {
            if (error.response && error.response.status === 401) {
                Swal.fire("Unauthorized", "Your session has expired. Please log in again.", "error");
                window.location.href = "/";
            }
        },
    }
};
</script>

<style scoped>
.bg-custom-color {
    background-color: #6c757d;
}

.button-spacing {
    margin-right: 10px;
}
</style>
