<template>
  <div class="container">
    <!-- Header Section -->
    <div class="header">
      <div class="greeting">
        <h1>Hey there, {{ user.name }}!</h1>
        <p>Welcome back, we're happy to have you here!</p>
      </div>
      <div class="search">
        <input v-model="searchQuery" placeholder="Search" type="text" />
        <i class="fas fa-search"></i>
      </div>
    </div>

    <!-- Main Content and Sidebar -->
    <div class="main-content">
      <!-- Stats and Courses Section -->
      <div class="content">
        <!-- Stats Section -->
        <div class="stats-section">
          <div class="stats">
            <div class="stat" v-for="(stat, index) in stats" :key="index">
              <i :class="stat.icon" :style="{ color: stat.color }"></i>
              <h3>{{ stat.title }}</h3>
              <p>{{ stat.value }}</p>
            </div>
          </div>

          <!-- Courses Section -->
          <div class="courses">
            <h2>Enrolled Courses</h2>
            <div class="course-list">
              <div class="course" v-for="(course, index) in courses" :key="index">
                <!-- Course Content (Image + Text) -->
                <div class="course-content">
                  <div class="course-image">
                    <img :src="course.image" :alt="course.title" />
                  </div>
                  <div class="course-text">
                    <h3>{{ course.title }}</h3>
                    <p>{{ course.description }}</p>
                  </div>
                </div>

                <!-- Course Footer (Button + Progress Bar) -->
                <div class="course-footer">
                  <button class="button">View Details</button>
                  <div class="progress">
                    <div class="progress-bar">
                      <div :style="{ width: course.progress + '%' }"></div>
                    </div>
                    <span>{{ course.completed }}/{{ course.total }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Mentors Section -->
        <div class="mentors">
        <h2>Top Mentors</h2>
        <table>
            <thead>
            <tr>
                <th>Mentor</th>
                <th>Skilled</th>
                <th>Rating</th>
                <th>Reviews</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(mentor, index) in mentors" :key="index">
                <td>
                <div class="mentor-info">
                    <img :src="mentor.image" alt="Mentor profile picture" height="40" width="40" />
                    <span>{{ mentor.location }}</span>
                </div>
                </td>
                <td>{{ mentor.skill }}</td>
                <td class="rating">{{ mentor.rating }} <i class="fas fa-star"></i></td>
                <td>{{ mentor.reviews }}</td>
                <td>
                <div class="button">Request First Session <i class="fas fa-arrow-right"></i></div>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
      </div>

      <!-- Sidebar Section (Calendar & Payment) -->
      <div class="sidebar">
        <div class="calendar">
          <h3>{{ currentMonth }}</h3>
          <table>
            <thead>
              <tr>
                <th>Mo</th>
                <th>Tu</th>
                <th>We</th>
                <th>Th</th>
                <th>Fr</th>
                <th>Sa</th>
                <th>Su</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(week, weekIndex) in calendar" :key="weekIndex">
                <td v-for="(day, dayIndex) in week" :key="dayIndex">
                  {{ day }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="btn">See Full Calendar</div>
        </div>
        <div class="payment">
          <h5 class="payment_title">Payment Method</h5>
          <i class="fas fa-wallet"></i>
          <p>Add Card & Buy Credits</p>
          <p class="payment_description">Lorem ipsum dolor sit amet ipsum olor sit amet</p>
          <a href="#" class="card_btn">Open</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  setup() {
    const user = ref({ name: 'Brian Ford' });
    const searchQuery = ref('');
    
    const stats = ref([
      { title: 'Session in Progress', value: 14, icon: 'fas fa-book-open', color: '#ffcc00' },
      { title: 'Session Completed', value: 8, icon: 'fas fa-check-circle', color: '#28a745' },
      { title: 'Certificates', value: 25, icon: 'fas fa-certificate', color: '#ff6b6b' },
    ]);

    const currentMonth = computed(() => {
      const options = { month: 'long' };
      return new Date().toLocaleDateString('en-US', options);
    });

    const calendar = computed(() => {
      const month = new Date().getMonth();
      const year = new Date().getFullYear();
      const firstDay = new Date(year, month, 1).getDay();
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const weeks = [];
      let week = new Array(7).fill('');

      for (let i = 0; i < firstDay; i++) {
        week[i] = '';
      }

      for (let day = 1; day <= daysInMonth; day++) {
        week[(firstDay + day - 1) % 7] = day;
        if ((firstDay + day) % 7 === 0 || day === daysInMonth) {
          weeks.push(week);
          week = new Array(7).fill('');
        }
      }
      return weeks;
    });

    const courses = ref([
      {
        title: 'Introduction to Web Development',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
        image: 'https://storage.googleapis.com/a1aa/image/dzu0WZ0rmz21Cj6BgI-RekwTxCg-nnsfLnGiNjQiJIE.jpg',
        progress: 60,
        completed: 6,
        total: 10,
      },
      {
        title: 'Introduction to Web Development',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
        image: 'https://storage.googleapis.com/a1aa/image/dzu0WZ0rmz21Cj6BgI-RekwTxCg-nnsfLnGiNjQiJIE.jpg',
        progress: 60,
        completed: 6,
        total: 10,
      },
      {
        title: 'Introduction to Web Development',
        description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
        image: 'https://storage.googleapis.com/a1aa/image/dzu0WZ0rmz21Cj6BgI-RekwTxCg-nnsfLnGiNjQiJIE.jpg',
        progress: 60,
        completed: 6,
        total: 10,
      },
    ]);

    const mentors = ref([
      {
        image: 'https://storage.googleapis.com/a1aa/image/8HwcKZtwaOmvMxt89-02hePRCZUfZde7HK6FYf-0Uo0.jpg',
        location: 'Grand Rapids',
        skill: 'IOS Developer',
        rating: 5.0,
        reviews: 192,
      },
      {
        image: 'https://storage.googleapis.com/a1aa/image/8HwcKZtwaOmvMxt89-02hePRCZUfZde7HK6FYf-0Uo0.jpg',
        location: 'Grand Rapids',
        skill: 'IOS Developer',
        rating: 5.0,
        reviews: 192,
      },
      {
        image: 'https://storage.googleapis.com/a1aa/image/8HwcKZtwaOmvMxt89-02hePRCZUfZde7HK6FYf-0Uo0.jpg',
        location: 'Grand Rapids',
        skill: 'IOS Developer',
        rating: 5.0,
        reviews: 192,
      },
    ]);

    return {
      user,
      searchQuery,
      stats,
      currentMonth,
      calendar,
      courses,
      mentors,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  flex-wrap: wrap;
}

.header .greeting {
  flex: 1;
}

.header h1 {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

.header p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.header .search {
  position: relative;
  margin-top: 10px;
  width: 300px;
}

.header .search input {
  width: 100%;
  padding: 10px 40px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.header .search i {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #999;
}

.main-content {
  display: flex;
  gap: 5px;
}

.content {
  flex: 1;
}

.stats-section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stats {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.stats .stat {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  flex: 1;
  min-width: 150px;
}

.stats .stat i {
  font-size: 24px;
  margin-bottom: 10px;
}

.stats .stat h3 {
  font-size: 16px;
  margin: 10px 0;
}

.stats .stat p {
  font-size: 16px;
  color: #666;
}

.courses {
  margin-top: 20px;
}

.courses h2 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.courses .course-list {
  display: flex;
  gap: 20px;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding-bottom: 10px;
}

.courses .course-list::-webkit-scrollbar {
  height: 8px;
}

.courses .course-list::-webkit-scrollbar-thumb {
  background: #007bff;
  border-radius: 4px;
}

.courses .course-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.course {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  flex: 0 0 calc(50% - 10px);
  box-sizing: border-box;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.course-content {
  display: flex;
  gap: 20px;
}

.course-image {
  flex: 0 0 100px;
}

.course-image img {
  width: 100%;
  border-radius: 10px;
}

.course-text {
  flex: 1;
}

.course-text h3 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.course-text p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.course-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.course-footer button {
  background: #fff;
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.course-footer .progress {
  flex: 1;
  margin-left: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.course-footer .progress-bar {
  width: 100%;
  height: 10px;
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}

.course-footer .progress-bar div {
  height: 100%;
  background: #007bff;
}

.course-footer .progress span {
  font-size: 14px;
  color: #666;
}

.sidebar {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sidebar .calendar,
.sidebar .payment {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.sidebar .calendar h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.sidebar .calendar table {
  width: 100%;
  border-collapse: collapse;
}

.sidebar .calendar table th,
.sidebar .calendar table td {
  padding: 5px;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.sidebar .calendar table th {
  background: #f5f7fa;
}

.sidebar .calendar .btn,
.sidebar .payment .btn {
  background: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
}

.sidebar .payment i {
  font-size: 24px;
  margin-bottom: 10px;
}

.sidebar .payment p {
  font-size: 14px;
  color: #666;
}

.mentors {
  margin-top: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-height: 500px; 
}

.mentors h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mentors table {
  width: 100%; 
  border-collapse: collapse;
  margin: 0 auto; 
  table-layout: auto;
}

.mentors table th,
.mentors table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.mentors table th {
  background: #f5f7fa;
  font-weight: 500;
}

.mentors table td img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.mentors table td .mentor-info {
  display: flex;
  align-items: center;
}

.mentors table td .rating {
  color: #ffcc00;
}

.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.button:hover {
  background-color: #f7f7f7;
  border-color: #ccc;
}

.button i {
  margin-left: 10px;
  font-size: 12px;
}

.payment_title{
text-align: start;
margin-bottom: 25px;
font-size: 15px;
}

.payment_description{
padding-bottom: 20px;
border-bottom: 1px solid #c3c3c3;
}
.card_btn {
text-decoration: none; 
font-size: 16px; 
padding: 0; 
margin: 0;
text-align: start;
display: inline-block;
margin-top: 10px; 
}
.card_btn:hover {
text-decoration: underline;
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .header .search {
    margin-top: 10px;
    width: 100%;
  }

  .stats {
    flex-direction: column;
  }

  .stats .stat {
    min-width: 100%;  
  }

  .courses .course-list {
    flex-direction: column;
  }

  .course {
    min-width: 100%; 
  }

  .main-content {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
  }

  .mentors table {
    width: 100%; 
  }
}

@media (max-width: 480px) {
  .header h1 {
    font-size: 20px;
  }

  .header p {
    font-size: 12px;
  }

  .stats .stat {
    height: auto;
    padding: 10px;
  }

  .stats .stat h3 {
    font-size: 14px;
  }

  .stats .stat p {
    font-size: 14px;
  }

  .mentors table th,
  .mentors table td {
    font-size: 10px;
    padding: 5px;
  }

  .mentors table td .mentor-info {
    flex-direction: column;
    align-items: flex-start;
  }

  .mentors table td .button {
    margin-top: 5px;
  }
}
</style>