<template>
    <div class="container">
        <div class="header">
            <h1>Choose your plan</h1>
            <p>You are currently on the Free plan. Upgrade to a Pro plan to access exclusive features designed to accelerate your learning.</p>
        </div>
        <div class="main-card">
            <div class="plans">
                <div class="plan-container">
                    <div class="plan-free-header">
                        <h2>Free</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed ipsum dolor.</p>
                        <button class="free-button">Your Plan</button>
                    </div>
                    <div class="pricing">
                        <h3>AED 0</h3>
                    </div>
                    <div class="included">
                        <h4>Included</h4>
                        <ul>
                            <li><i class="fas fa-circle"></i>Unlimited requests</li>
                            <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet</li>
                            <li><i class="fas fa-circle"></i>Consectetur adipiscing elit, sed.</li>
                            <li><i class="fas fa-circle"></i>Chat with 5 mentors</li>
                        </ul>
                    </div>
                </div>
                <div class="plan-container">
                    <div class="plan-quarter-header">
                        <h2>Pro Quarterly</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed ipsum dolor.</p>
                        <button class="upgrade-button">Upgrade</button>
                    </div>
                    <div class="pricing">
                        <h3>AED 100 <span>/ month</span></h3>
                        <span class="save">Save 22%</span>
                        <p>Billed AED 100 every 3 months</p>
                    </div>
                    <div class="included">
                        <h4>Included</h4>
                        <ul>
                            <li><i class="fas fa-circle"></i>Unlimited requests</li>
                            <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet</li>
                            <li><i class="fas fa-circle"></i>Consectetur adipiscing elit, sed.</li>
                            <li><i class="fas fa-circle"></i>Chat with 5 mentors</li>
                            <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet</li>
                            <li><i class="fas fa-circle"></i>Consectetur adipiscing elit, sed.</li>
                        </ul>
                    </div>
                </div>
                <div class="plan-container">
                    <div class="plan-monthly-header">
                        <h2>Pro Quarterly</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed ipsum dolor.</p>
                        <button class="month-upgrade-button">Upgrade</button>
                    </div>
                    <div class="pricing">
                        <h3>AED 100 <span>/ month</span></h3>
                        <p>Billed AED 100 every 3 months</p>
                    </div>
                    <div class="included">
                        <h4>Included</h4>
                        <ul>
                            <li><i class="fas fa-circle"></i>Unlimited requests</li>
                            <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet</li>
                            <li><i class="fas fa-circle"></i>Consectetur adipiscing elit, sed.</li>
                            <li><i class="fas fa-circle"></i>Chat with 5 mentors</li>
                            <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet</li>
                            <li><i class="fas fa-circle"></i>Consectetur adipiscing elit, sed.</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer">
                <ul>
                    <li><i class="fas fa-circle"></i>Prices are shown in USD.</li>
                    <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua adipiscing elit, sed do</li>
                    <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</li>
                    <li><i class="fas fa-circle"></i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.plan-container {
    width: 90%;
    max-width: 400px;
    background-color: #ffffff;
    border: 1px solid rgb(210, 214, 216);
    border-radius: 3px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.plan-free-header {
    background-color: white;
    color: black;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.plan-quarter-header {
    background-color: #0a4a6e;
    color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: left;
}
.plan-monthly-header {
    background-color: #e0f0f8;
    color: black;
    padding: 20px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.header{
    margin-bottom: 10px;
}

.header h2 {
    margin: 0;
    font-size: 1.5em;
}
.header p {
    margin: 10px 0 0;
    font-size: 0.9em;
}
.upgrade-button {
    background-color: #66bb6a;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    display: block;
    width: 100%;
    text-align: center;
}
.month-upgrade-button {
    color: black;
    border: 1px solid black;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    display: block;
    width: 100%;
    text-align: center;
}
.free-button {
    color: black;
    border: 1px solid black;
    padding: 10px 20px;
    margin: 20px 0;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    display: block;
    width: 100%;
    text-align: center;
}
.pricing {
    padding: 20px;
}
.pricing h3 {
    margin: 0;
    font-size: 1.5em;
    display: inline-block;
}
.pricing .save {
    color: #66bb6a;
    font-size: 0.9em;
    display: inline-block;
    float: right;
}
.pricing p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #888888;
    clear: both;
}
.included {
    padding: 20px;
}
.included h4 {
    margin: 0 0 10px;
    font-size: 1.2em;
}
.included ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.included ul li {
    margin: 10px 0;
    font-size: 0.9em;
    display: flex;
    align-items: center;
}
.included ul li i {
    color: #004d66;
    margin-right: 10px;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}
.main-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
}
.plans {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}
.footer{
    font-size: 14px;
    color: #0a4a6e;
    margin-top: 20px;
}
.footer ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.footer ul li {
    margin: 0 0 10px;
}
.footer ul li i {
        margin-right: 10px; 
}
@media (max-width: 768px) {
    .plans {
        flex-direction: column;
    }
    .plan {
        margin: 10px 0;
    }
}
</style>