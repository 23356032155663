<template>
    <div id="my-project-id">
        <div class="panel">
            <div class="mb-5">
                <div class="mb-4 flex items-center sm:flex-row flex-col sm:justify-between justify-center">
                    <div class="mb-4 mb-sm-0 title">
                        <i class="fas fa-calendar-alt"></i>
                        <span>My Schedule</span>
                    </div>
                    <div class="button-group">
                        <button type="button" class="btn btn-primary" @click="openJoinModal()">
                            <icon-plus class="mr-2" />
                            # Join with an ID
                        </button>
                        <button type="button" class="btn btn-primary" @click="editEvent()">
                            <icon-plus class="mr-2" />
                            + Create Meeting
                        </button>
                    </div>
                </div>

                <div class="calendar-wrapper">
                    <FullCalendar ref="calendar" :options="calendarOptions">
                        <template v-slot:eventContent="arg">
                            <div class="fc-event-main-frame d-flex align-items-center px-1 py-0.5 text-white">
                                <div class="fc-event-time font-weight-bold px-0.5">
                                    {{ arg.timeText }}
                                </div>
                                <div class="fc-event-title-container">
                                    <div class="fc-event-title fc-sticky font-weight-medium px-0.5">
                                        {{ arg.event.title }}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </FullCalendar>
                </div>
            </div>
        </div>

        <!-- Add Event Modal -->
        <TransitionRoot appear :show="isAddEventModal" as="template">
            <Dialog as="div" @close="isAddEventModal = false" class="relative z-[51]">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay 
                        class="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
                        enter="ease-out duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    />
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                    <div class="d-flex min-vh-100 align-items-center justify-content-center px-4 py-8">
                        <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100"
                            leave-to="opacity-0 scale-95"
                        >
                            <DialogPanel class="panel border-0 p-0 rounded-lg overflow-hidden w-100 max-w-lg text-black">
                                <button
                                    type="button"
                                    class="position-absolute top-4 end-4 text-muted hover:text-dark"
                                    @click="isAddEventModal = false"
                                >
                                    <span aria-hidden="true" class="h4 m-0">&times;</span>
                                </button>
                                <div class="h5 font-weight-medium bg-light py-3 px-3">
                                    {{ params.id ? 'Edit a session' : 'Schedule a session' }}
                                </div>
                                
                                <div class="p-4">
                                    <form @submit.prevent="saveEvent">
                                        <div class="mb-4">
                                            <label for="title">Title :</label>
                                            <input
                                                id="title"
                                                type="text"
                                                name="title"
                                                class="form-control"
                                                placeholder="Enter Event Title"
                                                v-model="params.title"
                                                required
                                            />
                                            <div class="text-danger mt-2" id="titleErr"></div>
                                        </div>

                                        <div class="mb-4 d-flex justify-content-between">
                                            <div class="w-49">
                                                <label for="dateStart">From :</label>
                                                <input
                                                    id="dateStart"
                                                    type="datetime-local"
                                                    name="start"
                                                    class="form-control w-custom"
                                                    placeholder="Event Start Date"
                                                    v-model="params.start"
                                                    :min="minStartDate"
                                                    @change="startDateChange($event)"
                                                    required
                                                />
                                                <div class="text-danger mt-2" id="startDateErr"></div>
                                            </div>

                                            <div class="w-49">
                                                <label for="dateEnd">To :</label>
                                                <input
                                                    id="dateEnd"
                                                    type="datetime-local"
                                                    name="end"
                                                    class="form-control w-custom"
                                                    placeholder="Event End Date"
                                                    v-model="params.end"
                                                    :min="minEndDate"
                                                    required
                                                />
                                                <div class="text-danger mt-2" id="endDateErr"></div>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <div class="flex items-center mb-4">
                                                <input class="mr-2" type="checkbox" v-model="isAllDay" />
                                                <label class="text-sm mb-0 font-medium text-gray-700">
                                                    All day
                                                </label>
                                                <span class="text-sm text-gray-700 ml-4">
                                                    Repeat:
                                                </span>
                                                <span class="text-sm text-blue-600 ml-2">
                                                    Every Friday
                                                </span>
                                                <button @click="editRepeat" class="text-sm text-blue-600 ml-2">
                                                    Edit
                                                </button>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <div class="flex justify-between items-center mb-4">
                                                <h3 class="text-lg font-semibold">
                                                    Session Details
                                                </h3>
                                                <button 
                                                    type="button"
                                                    @click="toggleGuestSection" 
                                                    class="add_guest_button text-white rounded-md px-4 py-2">
                                                    {{ isGuestSectionOpen ? 'Hide Guest' : 'Add Guest' }}
                                                </button>
                                            </div>
                                        </div>

                                        <div v-if="isGuestSectionOpen" class="mb-4">
                                            <h4 class="text-sm font-medium text-gray-700 mb-2">
                                                Add Guest
                                            </h4>
                                            <div class="flex items-center mb-4">
                                                <input 
                                                    v-model="newGuestEmail"
                                                    class="flex-grow p-2 border rounded-l-md" 
                                                    placeholder="Enter email address" 
                                                    type="email"
                                                />
                                                <button type="button" @click="addGuest" class="bg-teal-700 text-white px-4 py-2 rounded-r-md">Add</button>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <h4 class="text-sm font-medium text-gray-700 mb-2">
                                                People
                                            </h4>
                                            <div class="flex overflow-x-auto space-x-4 pb-2">
                                                <div v-for="(person, index) in people" :key="index" class="flex items-center space-x-4">
                                                    <div class="flex items-center">
                                                        <img :alt="'Profile picture of ' + person.name" class="w-8 h-8 rounded-full mr-2" height="32" :src="person.image" width="32"/>
                                                        <div>
                                                            <span class="text-sm text-gray-700">
                                                                {{ person.name }}
                                                            </span>
                                                            <span class="text-xs text-gray-500 ml-2">
                                                                {{ person.role }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <button @click="removePerson(index)" type="button" class="text-gray-500 hover:text-gray-700">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <label for="description">Event Description :</label>
                                            <textarea
                                                id="description"
                                                name="description"
                                                class="form-control"
                                                placeholder="Enter Event Description"
                                                v-model="params.description"
                                            ></textarea>
                                        </div>

                                        <div class="mb-4">
                                            <label>Session Color:</label>
                                            <div class="mt-3">
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" class="form-check-input text-primary" name="badge" value="primary" v-model="params.type" id="primary" />
                                                    <label for="primary"></label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" class="form-check-input text-info border-info" name="badge" value="info" v-model="params.type" id="info" />
                                                    <label for="info"></label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" class="form-check-input text-success border-success" name="badge" value="success" v-model="params.type" id="success" />
                                                    <label for="success"></label>
                                                </div>
                                                <div class="form-check form-check-inline">
                                                    <input type="radio" class="form-check-input text-danger border-danger" name="badge" value="danger" v-model="params.type" id="danger" />
                                                    <label for="danger"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-end align-items-center mt-4">
                                            <button type="button" class="btn btn-outline-danger" @click="isAddEventModal = false">Cancel</button>
                                            <button type="submit" class="btn btn-primary ml-2">
                                                {{ params.id ? 'Update Event' : 'Create Event' }}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>

            </Dialog>
        </TransitionRoot>

        <!-- Join Session Modal -->
        <TransitionRoot appear :show="isJoinModalOpen" as="template">
            <Dialog as="div" @close="closeJoinModal" class="relative z-[51]">
                <TransitionChild
                    as="template"
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-200 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-opacity-60" />
                </TransitionChild>

                <div class="fixed inset-0 overflow-y-auto">
                    <div class="d-flex min-vh-100 align-items-center justify-content-center px-4 py-8">
                        <TransitionChild
                            as="template"
                            enter="duration-300 ease-out"
                            enter-from="opacity-0 scale-95"
                            enter-to="opacity-100 scale-100"
                            leave="duration-200 ease-in"
                            leave-from="opacity-100 scale-100"
                            leave-to="opacity-0 scale-95"
                        >
                            <DialogPanel class="panel border-0 p-0 rounded-lg overflow-hidden custom-modal-right text-black">
                                <button
                                    type="button"
                                    class="position-absolute top-4 end-4 text-muted hover:text-dark"
                                    @click="closeJoinModal"
                                >
                                    <span aria-hidden="true" class="h4 m-0">&times;</span>
                                </button>
                                <div class="h5 font-weight-medium bg-light py-3 px-3">
                                    Join a session with an ID
                                </div>
                                <div class="p-3">
                                    <label for="session-id">Session ID*</label>
                                    <input type="text" id="session-id" v-model="sessionId" placeholder="Type a Session ID*" class="form-control w-100" />
                                    
                                    <label for="meeting-passcode" class="mt-3">Meeting passcode</label>
                                    <input type="text" id="meeting-passcode" v-model="meetingPasscode" placeholder="Type a Session Passcode" class="form-control w-100" />
                                    
                                    <div class="d-flex justify-content-end align-items-center mt-4">
                                        <button @click="joinSession" class="btn btn-primary w-100">Join Session</button>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </TransitionRoot>
    </div>
</template>

<script lang="js" setup>
    import { computed, onMounted, ref } from 'vue';
    import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogOverlay } from '@headlessui/vue';
    import FullCalendar from '@fullcalendar/vue3';
    import dayGridPlugin from '@fullcalendar/daygrid';
    import timeGridPlugin from '@fullcalendar/timegrid';
    import interactionPlugin from '@fullcalendar/interaction';
    import axios from "axios";
    import Swal from "sweetalert2";
    import api from "../api";
    
    const isModalOpen = ref(false);
    const isAddEventModal = ref(false);
    const isJoinModalOpen = ref(false);
    const sessionId = ref(''); 
    const meetingPasscode = ref(''); 
    const minStartDate = ref('');
    const minEndDate = ref('');
    const calendar = ref(null);
    const events = ref([]);
    const people = ref([]); 

    const defaultParams = ref({
        id: null,
        title: '',
        start: '',
        end: '',
        description: '',
        type: 'primary',
    });
    const params = ref({ ...defaultParams.value });
    const newGuestEmail = ref('');
    const isGuestSectionOpen = ref(false);
    const removedGuests = ref([]); 

    const removePerson = (index) => {
        removedGuests.value.push(people.value[index]);
        people.value.splice(index, 1); 
    };

    const addGuest = () => {
        if (newGuestEmail.value) {
            const guestName = newGuestEmail.value.split('@')[0]; 
            people.value.push({
                name: guestName,
                email: newGuestEmail.value,
                role: 'Guest',
                image: 'https://via.placeholder.com/32'
            });
            newGuestEmail.value = '';
        }
    };

    const toggleGuestSection = () => {
        isGuestSectionOpen.value = !isGuestSectionOpen.value; 
    };

    const openJoinModal = () => {
        isJoinModalOpen.value = true; 
    };
    const closeJoinModal = () => {
        isJoinModalOpen.value = false; 
        sessionId.value = ''; 
        meetingPasscode.value = '';
    };
    const joinSession = () => {
        console.log(`Joining session with ID: ${sessionId.value} and passcode: ${meetingPasscode.value}`);
        closeJoinModal(); 
    };

    const calendarOptions = computed(() => {
        return {
            plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
            initialView: 'dayGridMonth',
            headerToolbar: {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
            },
            editable: true,
            dayMaxEvents: true,
            selectable: true,
            droppable: true,
            eventClick: (info) => {
                const eventId = info.event.id;
                Swal.fire({
                    title: 'Event Options',
                    text: "What would you like to do?",
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Edit',
                    cancelButtonText: 'Delete',
                    allowOutsideClick: true,
                    customClass: {
                        cancelButton: 'custom-delete-button' // Custom class for the delete button
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        editEvent(info); 
                    } else if (result.isDismissed) {
                        if (result.dismiss === Swal.DismissReason.backdrop) {
                            return;
                        }
                        confirmDeleteEvent(eventId);
                    }
                });
            },
            select: (info) => {
                params.value = JSON.parse(JSON.stringify(defaultParams.value));
                params.value.start = dateFormat(info.start);
                params.value.end = dateFormat(info.end);
                people.value = []; 
                isAddEventModal.value = true; 
            },
            eventDrop: async (info) => {
                const updatedEvent = {
                    id: info.event.id,
                    start: dateFormat(info.event.start),
                    end: dateFormat(info.event.end),
                };
                await updateEvent(updatedEvent);
            },
            events: events.value,
        };
    });

    onMounted(() => {
        getEvents();
        getPeople();
    });

    const getEvents = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
            return;
        }

        try {
            const response = await api.get("/event/list", {
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.status) {
                events.value = response.data.data.map(event => ({
                    id: event.event_id, 
                    title: event.title,
                    start: event.start, 
                    end: event.end,     
                    className: event.event_color || 'primary',
                    description: event.description || '',
                }));
            } else {
                showMessage(response.data.message, 'error');
            }
        } catch (error) {
            console.error("Error fetching events:", error);
            showMessage("Failed to fetch events.", 'error');
        }
    };

    const getPeople = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            Swal.fire({ icon: "error", title: "Oops...", text: "Please log in to access this data." });
            return;
        }

        try {
            const response = await api.get("/people/list", { 
                headers: { Authorization: `Bearer ${token}` },
            });
            if (response.data.status) {
                people.value = response.data.data.map(person => ({
                    name: person.name,
                    role: person.role,
                    image: person.image || 'https://via.placeholder.com/32' 
                }));
            } else {
                showMessage(response.data.message, 'error');
            }
        } catch (error) {
            console.error("Error fetching people:", error);
            showMessage("Failed to fetch people.", 'error');
        }
    };

    const updateEvent = async (updatedEvent) => {
        const token = localStorage.getItem("token");
        try {
            await api.post(`/event/update/${updatedEvent.id}`, {
                start: updatedEvent.start,
                end: updatedEvent.end,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            showMessage('Event has been updated successfully.');
            Swal.fire({
                icon: 'success',
                title: 'Updated!',
                text: 'Event has been updated successfully.',
                confirmButtonText: 'OK'
            });
            getEvents();
        } catch (error) {
            console.error("Error updating event:", error);
            showMessage("Failed to update event.", 'error');
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to update event.',
                confirmButtonText: 'OK'
            });
        }
    };

    const editEvent = async (data = null) => {
        params.value = JSON.parse(JSON.stringify(defaultParams.value));
        
        if (data) {
            let obj = JSON.parse(JSON.stringify(data.event));
            const eventId = obj.id;

            try {
                const token = localStorage.getItem("token");
                const response = await api.get(`/event/edit/${eventId}`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data.status) {
                    const eventData = response.data.data;
                    params.value = {
                        id: eventData.event_id,
                        title: eventData.title,
                        start: dateFormat(eventData.start),
                        end: dateFormat(eventData.end),
                        type: eventData.event_color || 'primary',
                        description: eventData.description || '',
                    };
                    minStartDate.value = new Date();
                    minEndDate.value = dateFormat(eventData.start);
                    people.value = eventData.guests.map(guest => ({
                        name: guest.guest_name,
                        email: guest.guest_email 
                    }));
                } else {
                    showMessage(response.data.message, 'error');
                }
            } catch (error) {
                console.error("Error fetching event data:", error);
                showMessage("Failed to fetch event data.", 'error');
            }
        } else {
            minStartDate.value = new Date();
            minEndDate.value = new Date();
        }

        isAddEventModal.value = true;
    };

    const confirmDeleteEvent = (eventId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEvent(eventId);
            }
        });
    };

    const deleteEvent = async (eventId) => {
        const token = localStorage.getItem("token");
        try {
            await api.get(`/event/delete/${eventId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            showMessage('Event has been deleted successfully.');
            Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Event has been deleted successfully.',
                confirmButtonText: 'OK'
            });
            getEvents(); 
        } catch (error) {
            console.error("Error deleting event:", error);
            showMessage("Failed to delete event.", 'error');
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to delete event.',
                confirmButtonText: 'OK'
            });
        }
    };

    const dateFormat = (dt) => {
        dt = new Date(dt);
        const month = dt.getMonth() + 1 < 10 ? '0' + (dt.getMonth() + 1) : dt.getMonth() + 1;
        const date = dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate();
        const hours = dt.getHours() < 10 ? '0' + dt.getHours() : dt.getHours();
        const mins = dt.getMinutes() < 10 ? '0' + dt.getMinutes() : dt.getMinutes();
        dt = dt.getFullYear() + '-' + month + '-' + date + 'T' + hours + ':' + mins;
        return dt;
    };

    const saveEvent = async () => {
        if (!params.value.title || !params.value.start || !params.value.end) {
            return true; 
        }

        const token = localStorage.getItem("token");
        const eventData = {
            title: params.value.title,
            start: params.value.start,
            end: params.value.end,
            description: params.value.description,
            event_color: params.value.type,
            guests: people.value.map(person => ({
                guest_email: person.email 
            })),
            removed_guests: removedGuests.value 
        };

        try {
            if (params.value.id) {
                await api.post(`/event/update/${params.value.id}`, eventData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                showMessage('Event has been updated successfully.');
                Swal.fire({
                    icon: 'success',
                    title: 'Updated!',
                    text: 'Event has been updated successfully.',
                    confirmButtonText: 'OK'
                });
            } else {
                await api.post('/event/store', eventData, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                showMessage('Event has been created successfully.');
                Swal.fire({
                    icon: 'success',
                    title: 'Created!',
                    text: 'Event has been created successfully.',
                    confirmButtonText: 'OK'
                });
            }
            isAddEventModal.value = false;
            getEvents();
            removedGuests.value = []; 
            people.value = [];
        } catch (error) {
            console.error("Error saving event:", error);
            showMessage("Failed to save event.", 'error');
            Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Failed to save event.',
                confirmButtonText: 'OK'
            });
        }
    };

    const startDateChange = (event) => {
        const dateStr = event.target.value;
        if (dateStr) {
            minEndDate.value = dateFormat(dateStr);
            params.value.end = '';
        }
    };

    const showMessage = (msg = '', type = 'success') => {
        const toast = Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            timer: 3000,
            customClass: { container: 'toast' },
        });
        toast.fire({
            icon: type,
            title: msg,
            padding: '10px 20px',
        });
    };
</script>

<style scoped>
@import "../assets/css/tailwind.css";

::v-deep(.calendar-wrapper .fc-prev-button), 
::v-deep(.calendar-wrapper .fc-next-button) {
    background-color: transparent !important;
    color: rgb(75, 85, 99) !important;
    border: 2px solid rgb(229, 231, 235) !important;
    padding: 6px !important;
    display: flex !important;
    justify-content: center;
    margin-right: 15px !important;
}
::v-deep(.title) {
    display: flex;
    align-items: center;
}
::v-deep(.title i) {
    background-color: #335771;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin-right: 10px;
}
::v-deep(.title span) {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
::v-deep(.fc-toolbar-title){
display:none;
}
::v-deep(.btn-primary) {
    color: #fff;
    background-color: #004d61; 
    border: 1px solid #004d61;
    transition: background-color 0.2s ease, border-color 0.2s ease; 
}

::v-deep(.btn-primary:hover) {
    color: #fff; 
    background-color: #004d61; 
    border-color: #004d61; 
}

::v-deep(.btn-primary:focus) {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5); 
}

::v-deep(.btn-primary:active) {
    color: #fff;
    background-color: #004d61; 
    border-color: #004d61; 
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); 
}

::v-deep(.btn-primary:disabled) {
    color: #fff; 
    background-color: #004d61; 
    border-color: #004d61; 
    opacity: 0.65; 
    cursor: not-allowed; 
}

::v-deep(.fc-daygrid-day) {
    text-decoration: none !important; /* Remove underline */
}

::v-deep(.fc .fc-daygrid-day-frame) {
    min-height: 100%;
    position: relative;
}
::v-deep(.calendar-wrapper table td .fc-daygrid-day-number){
    padding: 16px;
}
::v-deep(.fc .fc-daygrid-day-number) {
    padding: 4px;
    position: relative;
    z-index: 4;
}
::v-deep(.fc .fc-daygrid-day-frame){
    min-height: 100%;
    position: relative;
}
::v-deep(a){
    color: inherit;
    text-decoration: inherit;
}
::v-deep(*, ::before, ::after){
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}
::v-deep(.fc, .fc *, .fc ::after, .fc ::before) {
    box-sizing: border-box;
}
.button-group {
    display: flex;
    gap: 5px; /* Space between buttons */
}

.button-group .btn {
    margin: 0; /* Remove default margin if any */
}
::v-deep(.fc-toolbar-chunk .fc-button-group .fc-button) {
    margin-right: 5px; /* Add space between buttons */
}

::v-deep(.fc-toolbar-chunk .fc-button-group .fc-button:last-child) {
    margin-right: 0; /* Remove margin from the last button */
}
::v-deep(.calendar-wrapper .fc-button-primary:not(:disabled):hover), 
::v-deep(.calendar-wrapper .fc-button-primary:not(:disabled).fc-button-active) {
    background-color: #004d61 !important; 
    color: #fff !important;
}

::v-deep(.w-100) {
    width: 100% !important;
}
::v-deep(.w-custom) {
    width: 88% !important;
}

::v-deep(.right-40){
    right: 13.9rem;
}

::v-deep(.top-16) {
    top: 45rem;
}

.custom-modal {
    width: 15% !important;
    max-width: 600px;
}

@media (max-width: 768px) {
    .custom-modal {
        width: 90%; 
    }
}
.custom-modal-right {
    position: absolute; 
    right: 4.2%; 
    top: 40%; 
    transform: translateY(-50%); 
    width: 350px; 
    max-width: 90%; 
}

.form-check-input {
display: none; 
}

.form-check-input + label {
position: relative;
padding-left: 10px; 
cursor: pointer;
}         
.form-check-input + label:before {
content: '';
position: absolute;
left: 0;
top: 50%;
transform: translateY(-50%);
width: 20px;
height: 20px;
border-radius: 50%;
border: 2px solid #ccc;
background-color: transparent; 
}
.form-check-input:checked + label:before {
border-color: transparent; 

}
.form-check-input.text-primary:checked + label:before {
background-color: #007bff;
border : 4px solid  #007bff;
}
.form-check-input.text-info:checked + label:before {
background-color: #17a2b8;
}
.form-check-input.text-success:checked + label:before {
background-color: #28a745; 
}
.form-check-input.text-danger:checked + label:before {
background-color: #dc3545; 
}
.add_guest_button {
background-color: #004d61;
}
</style>