<template>
  <div class="container">
    <h2>Account Settings</h2>

    <div class="section">
      <h3 style="margin-bottom: 5px;">Update Email</h3>
      <label for="email">Email address</label>
      <input type="text" id="email" v-model="email" />
    </div>

    <div class="section">
      <h3>Update Username</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua adipiscing
        elit, sed do sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua adipiscing
      </p>
      <label for="username-email">Email address</label>
      <input type="text" id="username-email" v-model="usernameEmail" />
    </div>

    <div class="section">
      <h3>Change Password</h3>
      <div class="password-fields">
        <div class="password-field">
          <label for="current-password">Current password</label>
          <input type="password" id="current-password" v-model="currentPassword" />
        </div>
        <div class="password-field">
          <label for="new-password">New password</label>
          <input type="password" id="new-password" v-model="newPassword" />
        </div>
        <div class="password-field">
          <label for="confirm-password">Confirm new password</label>
          <input type="password" id="confirm-password" v-model="confirmPassword" />
        </div>
      </div>
      <p class="password-requirements">Password should be at least 8 characters.</p>
      <div class="buttons">
        <button class="update-btn" @click="updatePassword">Update</button>
        <button class="cancel-btn" @click="cancelUpdate">Cancel</button>
      </div>
    </div>

    <div class="section delete-account">
      <h3>Delete Account</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua adipiscing
        elit, sed do sed do eiusmod tempor incididunt ut labore et dolore
        magna aliqua adipiscing
      </p>
      <button @click="deleteAccount">Delete My Account</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "info@fwmntorwise.com",
      usernameEmail: "info@fwmntorwise.com",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    };
  },
  methods: {
    updatePassword() {
      alert("Update Password Function is called"); 
    },
    cancelUpdate() {
      alert("Cancel Update Function is called");
    },
    deleteAccount() {
      alert("Delete Account Function is called");
    },
  },
};
</script>

<style scoped>
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}
.container {
  margin: 20px auto;
  padding: 20px;
}
h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
.section {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width:100%;
}
.section h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: start;
}
.section p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: justify;
  text-justify: inter-word;
}
.password-requirements{
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
  text-align: center !important;
}
.section label {
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
}
.section input[type="text"],
.section input[type="password"] {
  width: calc(30% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
.section input[type="password"]{
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}
.password-fields {
  display: flex;
  justify-content: space-between;
}
.password-field {
  flex: 1;
  margin-right: 10px;
}
.password-field:last-child {
  margin-right: 0;
}
.section .buttons {
  display: flex;
  gap: 10px;
}
.section .buttons button {
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border: none;
}
.section .buttons .update-btn {
  background-color: #7a9eaf;
  color: white;
}
.section .buttons .cancel-btn {
  background-color: white;
  color: black;
  border: 1px solid #7a9eaf;
}
.section .buttons .cancel-btn:hover {
  background-color: #7a9eaf;
  color: white;
}
.delete-account {
  text-align: left;

  justify-content: flex-start; 
}
.delete-account button {
  background-color: white;
  color: #d9534f;
  padding: 10px 20px;
  border: 1px solid #d9534f;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.delete-account button:hover {
  background-color: #d9534f;
  color: white;
}
@media (max-width: 600px) {
  .section input[type="text"],
  .section input[type="password"] {
    width: calc(100% - 10px);
  }
  .section .buttons {
    flex-direction: column;
  }
  .password-fields {
    flex-direction: column;
  }
  .password-field {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .password-field:last-child {
    margin-bottom: 0;
  }
}
</style>