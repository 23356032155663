<template>
  <div class="sidebar">
    <TabMenu :model="items">
      <template #item="{ item, props }">
        <a
          v-ripple
          @click="handleItemClick(item)"
          v-bind="props.action"
          :class="{ active: isActive(item.route) }"
        >
          <img
            v-if="item.iconBlack && !isActive(item.route)"
            :src="getIconPath(item.iconBlack)"
            alt="icon"
            class="sidebar-icon"
          />
          <img
            v-if="item.iconWhite && isActive(item.route)"
            :src="getIconPath(item.iconWhite)"
            alt="icon"
            class="sidebar-icon"
          />
          <span v-bind="props.label">{{ item.label }}</span>
          <span v-if="item.label === 'Account & Settings'" class="arrow">
            {{ isSubMenuVisible ? "↑" : "↓" }}
          </span>
        </a>
        <ul v-if="item.label === 'Account & Settings' && isSubMenuVisible" class="sub-menu">
          <li v-for="subitem in item.subtabs" :key="subitem.route">
            <a
              @click="handleSubItemClick(subitem)"
              :class="{ active: isActive(`/account-&-settings/${subitem.route}`) }"
            >
              {{ subitem.label }}
            </a>
          </li>
        </ul>
      </template>
    </TabMenu>

    <a href="" class="faq">FAQs</a>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import TabMenu from "primevue/tabmenu";

export default {
  components: { TabMenu },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const isSubMenuVisible = ref(false);

    const items = ref([
      { 
        label: "Dashboard",
        iconBlack: "home-icon-black.svg",
        iconWhite: "home-icon-white.svg",
        route: "/dashboard"
      },
      { 
        label: "My Request",
        iconBlack: "request-icon-black.svg",
        iconWhite: "request-icon-white.svg",
        route: "/my-request"
      },
      { 
        label: "My Schedule",
        iconBlack: "schedule-icon-black.svg",
        iconWhite: "schedule-icon-white.svg",
        route: "/my-schedule"
      },
      { 
        label: "My Course",
        iconBlack: "schedule-icon-black.svg",
        iconWhite: "schedule-icon-white.svg",
        route: "/my-courses"
      },
      { 
        label: "Session History",
        iconBlack: "session-icon-black.svg",
        iconWhite: "session-icon-black.svg",
        route: "/session-history"
      },
      { 
        label: "Feedback & Ratings",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-black.svg",
        route: "/feedback-&-ratings"
      },
      { 
        label: "Permissions",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-black.svg",
        route: "/permissions"
      },
      { 
        label: "Roles",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-black.svg",
        route: "/roles"
      },
      { 
        label: "Users",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-black.svg",
        route: "/users"
      },
      { 
        label: "Mentors",
        iconBlack: "feedback-icon-black.svg",
        iconWhite: "feedback-icon-black.svg",
        route: "/mentor-page"
      },
      {
        label: "Account & Settings",
        iconBlack: "settings-icon-black.svg",
        iconWhite: "account-icon-white.svg",
        route: "/account-&-settings",
        subtabs: [
          { 
          label: "Account Settings",
          route: "account-settings"
          },
          {
          label: "Notifications",
          route: "notifications"
          },
          {
          label: "Plans",
          route: "plans"
          },
          {
          label: "Billings",
          route: "billings"
          },
        ],

      },
    ]);

    const handleItemClick = (item) => {
      if (item.label === "Account & Settings") {
        isSubMenuVisible.value = !isSubMenuVisible.value;
      } else {
        isSubMenuVisible.value = false;
        router.push(item.route);
      }
    };

    const handleSubItemClick = (subitem) => {
      router.push(`/account-&-settings/${subitem.route}`);
    };

    const isActive = (routeName) => {
      return route.path === routeName || route.path.startsWith(routeName);
    };

    const getIconPath = (icon) => {
      return require(`@/assets/images/icons/${icon}`);
    };

    return {
      items,
      handleItemClick,
      handleSubItemClick,
      isActive,
      getIconPath,
      isSubMenuVisible,
    };
  },
};
</script>

<style scoped>
.sidebar {
  height: 100vh;
  margin: 40px 0px;
  border-right: 0.5px solid #a4a4a4;
}

.sidebar ::v-deep .p-tabmenu-ink-bar {
  background: #a4a4a4;
}

.sidebar ::v-deep .p-tabmenu-nav {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 12px;
  background: transparent;
  padding-right: 1px;
}

.sidebar ::v-deep .p-menuitem-link {
  color: black !important; /* Default color black */
  border: none;
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  border-radius: 0px;
  background: transparent;
}

.sidebar ::v-deep .p-tabmenu-nav a.active {
  background-color: var(--primary-blue);
  color: white !important; /* Active text color white */
  text-decoration: none;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  border-radius: 4px;
}

.faq {
  font-size: 18px;
  font-weight: 500;
  color: var(--primary-blue);
  text-decoration: none;
  padding: 0 1rem;
}

.sidebar-icon {
  margin-right: 10px;
}

.sub-menu {
  list-style: none;
  padding-left: 20px;
  margin: 5px 0;
}

.sub-menu li {
  margin-top: 5px;
}
.sub-menu a {
  font-size: 14px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  display: block;
  padding: 5px 10px;
  color: black !important;
  text-decoration: none;
  font-weight: 500;
  border-radius: 4px;
}

.sidebar ul.sub-menu li a::before {
  height: 0rem !important; 
  width: 0rem !important; 
  border-radius: 0.25rem;
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity, 1));
}

.sub-menu a.active {
  background-color: var(--primary-blue);
  color: white !important;
  border-radius: 4px;
}

.arrow {
  margin-left: auto;
  font-size: 14px;
  font-weight: bold;
}
</style>
