<template>
   <div class="container">
        <h2>Your Plan</h2>
        <p>You're currently on the Free plan. Upgrade your plan to get access to unlimited chatting and automated mentor matching.</p>
        <a href="#" class="btn">Upgrade To Pro</a>
        
        <h2>Payment Method</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
        <div class="card">
            <div class="card-container">
                <i class="fas fa-credit-card"></i>
                <span>Add Debit/Credit Card</span>
            </div>
        </div>
        
        <h2>Payment History</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
        <div class="card" style="height: 100px;"></div>
    </div>
</template>

<style scoped>
        .container{
            margin: 0 auto;
        }
        h2 {
            font-size: 1.5em;
            margin-bottom: 10px;
        }
        p {
            color: #6c757d;
            margin-bottom: 20px;
        }
        .btn {
            display: inline-block;
            padding: 10px 20px;
            font-size: 1em;
            color: #fff;
            background-color: rgba(51,87,113,1);
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            margin-bottom: 20px;
            transition: background-color 0.3s ease; 
        }
        .btn:hover {
            background-color: rgba(40, 70, 90, 1);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
            color:#fff;
        }
        .card{
        display: flex;
        flex-direction: row; 
        align-items: center;
        justify-content: start; 
        border: 1px solid #ced4da;
        border-radius: 8px;
        padding: 15px 20px;
        cursor: pointer;
        margin-bottom: 20px;
        gap: 10px;
        width: 100%;
       }

        .card i {
            font-size: 2em;
            color: rgba(51,87,113,1);
            margin-right: 8px;
        }
        .card span {
            font-size: 1em;
            color: rgba(51,87,113,1);
        }
        .card-container {
        display: inline-flex; 
        align-items: center;
        border: 1px solid #000;
        padding: 10px 100px 10px 10px; 
        border-radius: 5px;
        }
        .card-container i {
            margin-right: 5px; 
        }
</style>